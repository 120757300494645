<mat-drawer-container>
  <mat-drawer
    #drawer
    mode="side"
    disableClose
    [opened]="!mobileQuery.matches ? true : false"
  >
    <app-row justify="end" class="close">
      <app-col>
        <button mat-icon-button (click)="drawer.close()">
          <mat-icon color="primary"> close </mat-icon>
        </button>
      </app-col>
    </app-row>

    <a routerLink="/dashboard" class="d-flex py-3 px-4">
      <img
        alt="Wastics Logo"
        class="logo-drawer mx-auto"
        src="assets/images/wastics_horizontal.svg"
      />
    </a>

    <mat-divider class="ml-2"></mat-divider>
    <app-row
      class="admin"
      align="center"
      *ngIf="authService.isWasticsAdmin$ | async"
    >
      <app-col xs="12"> Admin </app-col>
    </app-row>

    <app-row class="name" align="center">
      <app-col>
        <span
          class="text-primary break-words"
          *ngIf="authService.company$ | async as company"
        >
          {{ company.name }}
        </span>
        <h3 class="mb-0 break-words" *ngIf="authService.user$ | async as user">
          {{ user.firstname }}
          {{ user.lastname }}
        </h3>
      </app-col>
    </app-row>
    <mat-divider></mat-divider>
    <mat-nav-list>
      <a mat-list-item routerLink="/dashboard" routerLinkActive="active">
        <mat-icon class="mr-2" color="primary">dashboard</mat-icon>
        Dashboard
      </a>

      <ng-container *ngIf="authService.isWasteDisposer$ | async">
        <mat-nav-list
          *ngIf="authService.isVerified$ | async"
          class="hide-if-empty"
        >
          <h2 *ngIf="showNavigationLabels$ | async" class="subsection mat-h3">
            Abfallsammler/&shy;-behandler
          </h2>

          <a
            mat-list-item
            routerLink="/entsorger-anfragen"
            routerLinkActive="active"
            appCanActivatePath
          >
            <mat-icon class="mr-2" color="primary">delete_sweep</mat-icon>
            Kunden-Anfragen
          </a>

          <a
            mat-list-item
            routerLink="/entsorger-angebote"
            routerLinkActive="active"
            appCanActivatePath
          >
            <mat-icon class="mr-2" color="primary">local_offer</mat-icon>
            Meine Angebote
          </a>

          <mat-expansion-panel
            [class.mat-elevation-z0]="true"
            appExpandActiveNavigation
            dense
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <a mat-list-item>
                  <mat-icon class="mr-2" color="primary">
                    history_edu
                  </mat-icon>
                  Verträge
                </a>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list dense>
              <a
                mat-list-item
                routerLink="/entsorger-vertraege"
                routerLinkActive="active"
                appCanActivatePath
              >
                <mat-icon class="mr-2" color="primary">summarize</mat-icon>
                Meine Verträge
              </a>

              <a
                mat-list-item
                routerLink="/entsorger-ausstehende-vertraege"
                routerLinkActive="active"
                appCanActivatePath
              >
                <mat-icon class="mr-2" color="primary"
                  >pending_actions</mat-icon
                >
                Ausstehende Verträge
              </a>

              <a
                mat-list-item
                routerLink="/vertraege/entwuerfe"
                routerLinkActive="active"
                appCanActivatePath
              >
                <mat-icon class="mr-2" color="primary">edit_square</mat-icon>
                Entwürfe
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <a
            mat-list-item
            routerLink="/auftragsverwaltung"
            routerLinkActive="active"
            appCanActivatePath
          >
            <mat-icon class="mr-2" color="primary">manage_search</mat-icon>
            Auftragsverwaltung
          </a>

          <a
            mat-list-item
            routerLink="/enb/dokumente"
            routerLinkActive="active"
            appCanActivatePath
          >
            <mat-icon class="mr-2" color="primary">folder</mat-icon>
            Dokumente
          </a>

          <mat-expansion-panel
            [class.mat-elevation-z0]="true"
            appCanActivatePath="betrieb"
            appExpandActiveNavigation
            dense
            *ngIf="(authService.isWasticsAdmin$ | async) === false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <a mat-list-item>
                  <mat-icon class="mr-2" color="primary">business</mat-icon>
                  Mein Betrieb
                </a>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list dense style="overflow: hidden">
              <a
                mat-list-item
                routerLink="/betrieb/vertragsbedingungen"
                routerLinkActive="active"
              >
                <mat-icon class="mr-2" color="primary"> grading</mat-icon>
                Vertragsbedingungen
              </a>

              <a
                mat-list-item
                routerLink="/betrieb/anlagen"
                routerLinkActive="active"
                appCanActivatePath="anlagen"
              >
                <mat-icon class="mr-2" color="primary">
                  precision_manufacturing
                </mat-icon>
                Meine Anlagen
              </a>

              <a
                mat-list-item
                routerLink="/betrieb/stoffstroeme"
                routerLinkActive="active"
                appCanActivatePath="stoffstroeme"
              >
                <mat-icon class="mr-2" color="primary"> view_stream</mat-icon>
                Meine Stoffströme
              </a>

              <a
                mat-list-item
                routerLink="/betrieb/zertifikate"
                routerLinkActive="active"
                appCanActivatePath="zertifikate"
              >
                <mat-icon class="mr-2" color="primary"
                  >card_membership</mat-icon
                >
                Zertifikate
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-nav-list>
      </ng-container>

      <ng-container *ngIf="authService.isWasteProducer$ | async">
        <mat-nav-list class="hide-if-empty">
          <h2 *ngIf="showNavigationLabels$ | async" class="subsection mat-h3">
            Abfallerzeuger
          </h2>

          <ng-container *ngIf="authService.isVerified$ | async">
            <mat-expansion-panel
              [class.mat-elevation-z0]="true"
              appCanActivatePath="neue-entsorgungsanfrage"
              appExpandActiveNavigation
              dense
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <a mat-list-item>
                    <mat-icon class="mr-2" color="primary">
                      restore_from_trash
                    </mat-icon>
                    Ausschreibungen
                  </a>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list dense>
                <a
                  mat-list-item
                  routerLink="/neue-entsorgungsanfrage"
                  routerLinkActive="active"
                >
                  <mat-icon class="mr-2" color="primary">
                    restore_from_trash
                  </mat-icon>
                  Neue Anfrage
                </a>
                <a
                  mat-list-item
                  routerLink="/erzeuger-anfragen"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <mat-icon class="mr-2" color="primary">list_alt</mat-icon>
                  Meine Anfragen
                </a>

                <a
                  mat-list-item
                  routerLink="/erzeuger-anfragen/angebote"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <mat-icon class="mr-2" color="primary">local_offer</mat-icon>
                  Angebote
                </a>

                <a
                  mat-list-item
                  routerLink="/erzeuger-anfragen/entwuerfe"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <mat-icon class="mr-2" color="primary">edit</mat-icon>
                  Entwürfe
                </a>
              </mat-nav-list>
            </mat-expansion-panel>

            <mat-expansion-panel
              [class.mat-elevation-z0]="true"
              appCanActivatePath="neue-entsorgungsanfrage"
              appExpandActiveNavigation
              dense
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <a mat-list-item>
                    <mat-icon class="mr-2" color="primary">
                      history_edu
                    </mat-icon>
                    Verträge
                  </a>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list dense>
                <a
                  mat-list-item
                  routerLink="/erzeuger-vertraege"
                  routerLinkActive="active"
                  appCanActivatePath
                >
                  <mat-icon class="mr-2" color="primary">summarize</mat-icon>
                  Meine Verträge
                </a>

                <a
                  mat-list-item
                  routerLink="/erzeuger-ausstehende-vertraege"
                  routerLinkActive="active"
                  appCanActivatePath
                >
                  <mat-icon class="mr-2" color="primary"
                    >pending_actions</mat-icon
                  >
                  Ausstehende Verträge
                </a>

                <a
                  mat-list-item
                  routerLink="/vertraege/entwuerfe"
                  routerLinkActive="active"
                  appCanActivatePath
                >
                  <mat-icon class="mr-2" color="primary">edit_square</mat-icon>
                  Entwürfe
                </a>
              </mat-nav-list>
            </mat-expansion-panel>

            <a
              mat-list-item
              routerLink="/auftrage"
              routerLinkActive="active"
              appCanActivatePath
            >
              <mat-icon class="mr-2" color="primary">assignment</mat-icon>
              Meine Aufträge
            </a>
            <a
              mat-list-item
              routerLink="/abfallstrome"
              routerLinkActive="active"
              appCanActivatePath
            >
              <mat-icon class="mr-2" color="primary">category</mat-icon>
              Abfallströme
            </a>

            <a
              mat-list-item
              routerLink="/aez/dokumente"
              routerLinkActive="active"
              appCanActivatePath
            >
              <mat-icon class="mr-2" color="primary">folder</mat-icon>
              Dokumente
            </a>

            <mat-expansion-panel
              [class.mat-elevation-z0]="true"
              dense
              appCanActivatePath="awk"
              appExpandActiveNavigation
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <a mat-list-item>
                    <mat-icon class="mr-2" color="primary"
                      >description</mat-icon
                    >
                    AWK
                  </a>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list dense>
                <a
                  mat-list-item
                  routerLink="/awk/list"
                  routerLinkActive="active"
                >
                  <mat-icon class="mr-2" color="primary"
                    >format_list_bulleted</mat-icon
                  >
                  AWK Übersicht</a
                >
                <a
                  mat-list-item
                  (click)="openCreateEditAWKDialog()"
                  routerLinkActive="active"
                >
                  <mat-icon class="mr-2" color="primary">note_add</mat-icon>
                  AWK erstellen</a
                >
              </mat-nav-list>
            </mat-expansion-panel>

            <mat-expansion-panel
              [class.mat-elevation-z0]="true"
              appCanActivatePath="betrieb"
              appExpandActiveNavigation
              dense
              *ngIf="(authService.isWasticsAdmin$ | async) === false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <a mat-list-item>
                    <mat-icon class="mr-2" color="primary">business</mat-icon>
                    Mein Betrieb
                  </a>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list dense style="overflow: hidden">
                <a
                  mat-list-item
                  routerLink="/betrieb/abfallarten"
                  routerLinkActive="active"
                  appCanActivatePath="abfallarten"
                >
                  <mat-icon class="mr-2" color="primary">recycling</mat-icon>
                  Meine Abfallarten
                </a>
              </mat-nav-list>
              <mat-nav-list dense style="overflow: hidden">
                <a
                  mat-list-item
                  routerLink="/betrieb/entsorgungspartner"
                  routerLinkActive="active"
                  appCanActivatePath="entsorgungspartner"
                >
                  <mat-icon class="mr-2" color="primary">handshake</mat-icon>
                  Entsorgungspartner
                </a>
              </mat-nav-list>
              <mat-nav-list dense style="overflow: hidden">
                <a
                  mat-list-item
                  routerLink="/betrieb/sammelstellen"
                  routerLinkActive="active"
                  appCanActivatePath="sammelstellen"
                >
                  <mat-icon class="mr-2" color="primary">flag</mat-icon>
                  Sammelstellen
                </a>
              </mat-nav-list>
            </mat-expansion-panel>
          </ng-container>
        </mat-nav-list>
      </ng-container>

      <ng-container *ngIf="authService.isWasticsAdmin$ | async">
        <mat-nav-list>
          <h2 *ngIf="showNavigationLabels$ | async" class="subsection mat-h3">
            Administration
          </h2>

          <mat-expansion-panel
            [class.mat-elevation-z0]="true"
            dense
            appExpandActiveNavigation
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <a mat-list-item>
                  <mat-icon class="mr-2" color="primary"> handshake </mat-icon>
                  Matchmaking
                </a>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list dense>
              <a
                mat-list-item
                routerLink="admin/anfragen"
                routerLinkActive="active"
              >
                <mat-icon class="mr-2" color="primary"> delete_sweep</mat-icon>
                Anfragen
              </a>

              <a
                mat-list-item
                routerLink="admin/matches"
                routerLinkActive="active"
              >
                <mat-icon class="mr-2" color="primary">handshake</mat-icon>
                Matches
              </a>

              <!-- <a
                mat-list-item
                routerLink="admin/contracts"
                routerLinkActive="active"
              >
                <mat-icon class="mr-2" color="primary">history_edu</mat-icon>
                Verträge
              </a>

              <a
                mat-list-item
                routerLink="admin/documents"
                routerLinkActive="active"
              >
                <mat-icon class="mr-2" color="primary">folder</mat-icon>
                Dokumente
              </a> -->
            </mat-nav-list>
          </mat-expansion-panel>

          <mat-expansion-panel
            [class.mat-elevation-z0]="true"
            dense
            appExpandActiveNavigation
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <a mat-list-item>
                  <mat-icon class="mr-2" color="primary">
                    display_settings
                  </mat-icon>
                  Administration
                </a>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list dense>
              <a
                mat-list-item
                routerLink="admin/firmen"
                routerLinkActive="active"
              >
                <mat-icon class="mr-2" color="primary">business</mat-icon>
                Unternehmen
              </a>

              <a
                mat-list-item
                routerLink="admin/benutzer"
                routerLinkActive="active"
              >
                <mat-icon class="mr-2" color="primary">person</mat-icon>
                Benutzer
              </a>

              <a
                mat-list-item
                routerLink="admin/zertifikate"
                routerLinkActive="active"
              >
                <mat-icon class="mr-2" color="primary"
                  >card_membership</mat-icon
                >
                Zertifikate
              </a>

              <a
                mat-list-item
                routerLink="admin/vertragsbedingungen"
                routerLinkActive="active"
              >
                <mat-icon class="mr-2" color="primary">grading</mat-icon>
                Vertragsbedingungen
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-nav-list>
      </ng-container>
      <mat-nav-list>
        <ng-container *ngIf="authService.isVerified$ | async">
          <h2 *ngIf="showNavigationLabels$ | async" class="subsection mat-h3">
            Allgemein
          </h2>
          <mat-expansion-panel
            [class.mat-elevation-z0]="true"
            dense
            appCanActivatePath="stammdaten"
            appExpandActiveNavigation
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <a mat-list-item>
                  <mat-icon class="mr-2" color="primary">dataset</mat-icon>
                  Stammdaten
                </a>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list dense style="overflow: hidden">
              <a
                mat-list-item
                routerLink="/stammdaten/company-profile"
                routerLinkActive="active"
              >
                <mat-icon class="mr-2" color="primary">business</mat-icon>
                Unternehmen
              </a>

              <a
                mat-list-item
                routerLink="/stammdaten/standorte"
                routerLinkActive="active"
              >
                <mat-icon class="mr-2" color="primary">location_on</mat-icon>
                Standorte
              </a>

              <a
                mat-list-item
                routerLink="/stammdaten/user-profile"
                routerLinkActive="active"
              >
                <mat-icon class="mr-2" color="primary">person</mat-icon>
                Profil / Passwort
              </a>

              <a
                mat-list-item
                routerLink="/stammdaten/kontakt-einstellungen"
                routerLinkActive="active"
              >
                <mat-icon class="mr-2" color="primary">mail</mat-icon>
                Benachrichtigungs-<br />
                einstellungen
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </ng-container>
        <a
          mat-list-item
          *ngIf="!isLoggedin()"
          type="button"
          (click)="loginSession()"
        >
          <mat-icon class="mr-2" color="primary">login</mat-icon>
          Login Session
        </a>

        <a
          mat-list-item
          *ngIf="isLoggedin()"
          type="button"
          (click)="logoutSession()"
        >
          <mat-icon class="mr-2" color="primary">logout</mat-icon>
          Logout
        </a>

        <a
          mat-list-item
          *ngIf="!isLoggedin()"
          type="button"
          (click)="register()"
        >
          <mat-icon class="mr-2" color="primary">app_registration</mat-icon>
          Register
        </a>

        <a mat-list-item routerLink="/legal" routerLinkActive="active">
          <mat-icon class="mr-2" color="primary">info_outlined</mat-icon>
          AGB / Impressum
        </a>
      </mat-nav-list>
    </mat-nav-list>
  </mat-drawer>

  <mat-drawer-content>
    <mat-toolbar>
      <button class="menu-button" mat-button (click)="drawer.toggle()">
        <mat-icon class="menu" color="primary">
          {{ drawer.opened ? 'close' : 'menu' }}
        </mat-icon>
        <span class="fs-3">Menü</span>
      </button>

      <div class="logo">
        <a routerLink="/dashboard">
          <img alt="Wastics Logo" src="assets/images/isotype.svg" />
        </a>
      </div>

      <app-notification-popup></app-notification-popup>
    </mat-toolbar>
    <main class="main container py-4 py-md-3 content">
      <ng-content></ng-content>
    </main>
  </mat-drawer-content>
</mat-drawer-container>
