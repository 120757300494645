<!-- <div class="button-content" *ngIf="!this.pushNotificationsGranted">
  <button
    mat-mini-fab
    class="notification-button"
    color="primary"
    (click)="openNotificationDialog()"
  >
    <mat-icon aria-hidden="false" aria-label="notification-icon"
      >notifications</mat-icon
    >
  </button>
</div> -->

<!-- <div class="button-content" *ngIf="this.pushNotificationsGranted">
  <button
    mat-mini-fab
    class="notification-button"
    [matMenuTriggerFor]="menu"
    color="primary"
  >
    <mat-icon aria-hidden="false" aria-label="notification-icon"
      >notifications</mat-icon
    >
  </button>

  <div class="button-badge" *ngIf="numberOfUnRead !== 0">
    {{ numberOfUnRead }}
  </div>
</div> -->

<mat-menu #menu="matMenu" xPosition="before" class="my-menu">
  <div
    *ngFor="let notification of sortedNotifications$ | async"
    class="notification-body"
  >
    <div class="notification-item">
      <div class="notification-badge" *ngIf="!notification.hasRead">Neu</div>
      <h3 class="title">{{ notification.title }}</h3>
      <p class="message">{{ notification.body }}</p>
      <a [href]="notification.url" class="link">Zur Ansicht navigieren.</a>
    </div>
    <mat-divider></mat-divider>
  </div>

  <div class="notification-item">
    <button
      mat-flat-button
      color="primary"
      (click)="newNotifications(); $event.stopPropagation()"
    >
      Weitere Benachrichtungen
    </button>
  </div>
</mat-menu>
